import { FC, PropsWithChildren } from 'react'

export const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
    return (
        <section className='h-screen'>
            <div className='container h-full px-6'>
                <div className='g-6 flex h-full flex-wrap items-center justify-center lg:justify-between'>
                    <div className='mb-12 md:mb-0 md:w-8/12 lg:w-6/12'>
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img src='https://d1pnnwteuly8z3.cloudfront.net/images/30ba08ff-7870-4ce4-855e-cc07414e7915/b345644c-a350-44b9-bd80-4332b12161f5.png' className='w-full' alt='Phone image' />
                    </div>
                    <div className='md:w-8/12 lg:ml-6 lg:w-5/12'>{children}</div>
                </div>
            </div>
        </section>
    )
}
