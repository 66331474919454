import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { CustomerCenterLocalStorageKey } from '../utils'

export function useLocalStorage() {
    const router = useRouter()
    const [localStorageRes, setLocalStorageRes] = useState({ teamId: '', username: '', tenantUserId: '' })
    useEffect(() => {
        const teamId = localStorage.getItem(CustomerCenterLocalStorageKey.CLOSER_TEAM_ID) || ''
        const tenantUserId = localStorage.getItem(CustomerCenterLocalStorageKey.CLOSER_TENANT_USER_ID) || ''
        const username = localStorage.getItem(CustomerCenterLocalStorageKey.CLOSER_USERNAME) || ''
        setLocalStorageRes({...{ teamId, username, tenantUserId }})
    }, [router.route])
    return localStorageRes
}
