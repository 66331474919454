import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { FormEvent, useEffect, useState } from 'react'

import { useAppSelector } from '@closer/redux-storage'
import { useMatrix } from '@closer/headless-components/hooks'
import { NoMatrixAccount, NoWhatsappIntegrationError } from '@closer/types'

import { BaseLayout } from '../../layout'
import { CustomSubmitButton } from '../../components/Button'

const LoginPage: NextPage = () => {
    const { login } = useMatrix()
    const [email, setEmail] = useState('')
    const router = useRouter()
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const tenantUser = useAppSelector(state => state.closerUser.tenantUser)

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            await login(email, password, 'web')
        } catch (error: any) {
            if (error instanceof NoMatrixAccount) {
                router.push('/register')
            }
            if (error instanceof NoWhatsappIntegrationError) {
                router.push('/register/scan')
            } else {
                // eslint-disable-next-line no-alert
                window.alert(error.message)
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (tenantUser) {
            if (router.query['redirect'] && router.query['code']) {
                if (router.query['type'] === 'hubspot') {
                    router.push({ pathname: '/oauth/hubspot', query: { code: router.query['code'] } })
                }
                if (router.query['type'] === 'salesforce') {
                    router.push({ pathname: '/oauth/salesforce', query: { code: router.query['code'] } })
                }
            } else if (tenantUser.matrixAccount?.integration.whatsapp) {
                router.push('/app')
            } else {
                router.push('/register/scan')
            }
        }
    }, [tenantUser, router])

    return (
        <BaseLayout>
            <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
                <div className='w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8 border border-gray-300'>
                    <form onSubmit={onSubmit}>
                        <div className='mb-6'>
                            <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                                email
                            </label>
                            <input
                                type='email'
                                id='email'
                                value={email}
                                onChange={e => {
                                    setEmail(e.currentTarget.value)
                                }}
                                className='shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light'
                                placeholder='example@example.com'
                                required
                            />
                        </div>
                        <div className='mb-6'>
                            <label htmlFor='password' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                                password
                            </label>
                            <input
                                type='password'
                                autoComplete='on'
                                id='password'
                                value={password}
                                onChange={e => {
                                    setPassword(e.currentTarget.value)
                                }}
                                className='shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light'
                                required
                            />
                        </div>
                        <div className='pt-1 pb-1 text-center'>
                            <CustomSubmitButton label='Log in' isLoading={isLoading} />
                            <hr className='mb-6 border-t' />
                            <div className='text-center'>
                                <a
                                    className='inline-block text-sm text-blue-500 align-baseline hover:text-blue-800'
                                    href='#'
                                    onClick={() => {
                                        router.push('forgot_password')
                                    }}>
                                    Forgot password?
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </BaseLayout>
    )
}

export default LoginPage
