import Image from 'next/image'
import Link from 'next/link'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { FC, PropsWithChildren } from 'react'

import { useLocalStorage } from '../customer-center/hooks/useLocalStorage'

export const CustomerCenterLayout: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className='h-screen w-screen grid grid-rows-[max-content,1fr,max-content] pb-1'>
            <Nav />
            {children}
            <Footer />
        </div>
    )
}

const Nav: FC = () => {
    const router = useRouter()
    const linkClassName = 'px-2 py-1 hover:bg-slate-200 rounded-md'
    const { username } = useLocalStorage()

    const queryClient = useQueryClient()
    const logout = () => {
        queryClient.clear()
        window.localStorage.clear()
        router.push('/customer-center')
    }

    return (
        <div className='shadow-sm'>
            <div className='grid grid-cols-[max-content,1fr,max-content] gap-2 items-center'>
                <div className='w-24'>
                    <Image
                        width={96}
                        height={32.6}
                        src='https://d1pnnwteuly8z3.cloudfront.net/images/30ba08ff-7870-4ce4-855e-cc07414e7915/b345644c-a350-44b9-bd80-4332b12161f5.png'
                        alt='Closer Logo'
                    />
                </div>
                <div className='h-full flex items-center'>
                    {/* <Link href='/customer-center' className={linkClassName}>
                        Login
                    </Link> */}
                    <Link href='/customer-center/conversations' className={linkClassName}>
                        Conversations
                    </Link>
                    <Link href='/customer-center/labelled' className={linkClassName}>
                        Labelled
                    </Link>
                    <Link href='/customer-center/settings' className={linkClassName}>
                        Settings
                    </Link>
                </div>
                <div className='grid grid-flow-row'>
                    <div>{username}</div>
                    <button className='p-1 bg-gray-50 cursor-pointer rounded-md border-0 mx-auto hover:bg-slate-300' onClick={logout}>
                        Logout
                    </button>
                </div>
            </div>
        </div>
    )
}

const Footer: FC = () => {
    return <></>
}
